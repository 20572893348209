import React from "react";
import {MDXProvider} from '@mdx-js/react';
import {Header} from "../components/Header";
import {Footer} from "../components/Footer";

// @ts-ignore
const Layout = ({data, children, additionalClass}) => {
    return <>
        <div className={'outer-layout d-flex p-3 min-vh-100 min-vw-100 mx-auto flex-column ' + additionalClass ?? ''}>
            <Header />
            {children}
            <Footer />
        </div>
    </>;
}

export default Layout;